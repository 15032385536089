import React from "react"
import Link from "next/link"
import { Container, Row, Col } from "react-bootstrap"
import Image from "next/image"
import styles from "./FivaFooter.module.css"
import DarkSocialMediaIcons from "../../SocialMediaIcons/DarkSocialMediaIcons"
import { MdEmail } from "react-icons/md"
import { MdPhone } from "react-icons/md"
import { Pages } from "../../../constants/_pages"
const LOGO = "/Images/FivaIcon.svg"

const FivaFooter = ({ blue = true }: { blue?: boolean }) => {
  const backgroundColor = blue
    ? { backgroundColor: "var(--fiva-secondary-color, #ddfffd)" }
    : {
        background: `linear-gradient(to bottom,white 15%,var(--Azul-claro, #ddfffd) 80%)`,
      }

  return (
    <footer
      style={{
        margin: "0",
        padding: "4.5rem 1rem 1rem 1rem",
        color: "var(--Morado-Fiva, #401C72)",
        ...backgroundColor,
      }}
    >
      <Container fluid className={styles.container}>
        <Row>
          <Col
            xs={{ span: 12 }}
            md={{ span: 6, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            xl={{ span: 7, offset: 1 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <Image src={LOGO} alt="Fiva Logo" width={160} height={95} />
              </Link>
              <Image
                src="/Images/enisa.png"
                alt="Certificado Enisa"
                width={140}
                height={140}
              />
            </div>
          </Col>
        </Row>
        <br />
        <Row
          style={{
            fontSize: "0.8em",
          }}
        >
          <Col
            xs={{ span: 12 }}
            md={{ span: 9, offset: 1 }}
            lg={{ span: 9, offset: 1 }}
            xl={{ span: 2, offset: 1 }}
            style={{ marginBottom: "4%" }}
          >
            <p>
              En Fiva nos comprometemos a reducir la factura de nuestros
              clientes aportándoles los mejores servicios y productos. En Fiva
              tenemos el comparador de tarifas de luz más preciso del mercado.
            </p>
            <Col
              xs={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <DarkSocialMediaIcons />
            </Col>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 3, offset: 1 }}
            lg={{ span: 3, offset: 1 }}
            xl={{ span: 2, offset: 1 }}
            style={{ marginBottom: "3%" }}
          >
            <h4>Servicios</h4>
            <p>Comparativa y contratación</p>
            <p>Ajuste de optimización de potencia</p>
            <p>Estudios de eficiencia energética</p>
            <p>Transición a energía renovable</p>
            <p>Soluciones de software</p>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 3, offset: 1 }}
            lg={{ span: 3, offset: 1 }}
            xl={{ span: 2, offset: 1 }}
            className={styles.linkContainer}
          >
            <h4>Fiva</h4>
            <p>
              <Link href={Pages.energy}>Eficiencia energética</Link>
            </p>
            <p>
              <Link href="/solar">Autoconsumo</Link>
            </p>
            <p>
              <Link href="/blog">Blog</Link>
            </p>
            <p>
              <Link href="/contacto">Contacto</Link>
            </p>
            <p>
              <Link href="/sube-tu-factura">Comparador</Link>
            </p>
          </Col>

          <Col
            xs={{ span: 12 }}
            md={{ span: 4 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            style={{ marginBottom: "3%" }}
          >
            <h4>Contacto</h4>
            <a href="mailto:contacto@fiva.es">
              <p>
                <MdEmail
                  style={{
                    marginRight: "8px",
                    verticalAlign: "middle",
                    fontSize: "1rem",
                  }}
                />
                contacto@fiva.es
              </p>
            </a>
            <a href="tel:+34 624 64 41 37">
              <p>
                <MdPhone
                  style={{
                    marginRight: "8px",
                    verticalAlign: "middle",
                    fontSize: "1rem",
                  }}
                />
                624 64 41 37
              </p>
            </a>
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <Col
            xs={{ span: 12 }}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 7, offset: 1 }}
            xl={{ span: 7, offset: 1 }}
          >
            <div
              style={{
                display: "flex",
                gap: "5%",
                alignItems: "center",
                marginBottom: "3%",
              }}
            >
              <div>©2024 fiva.</div>
              <div style={{ display: "flex", gap: "5%", alignItems: "center" }}>
                <img
                  src="https://res.cloudinary.com/dzzkeb6xp/image/upload/v1684617444/spain_pw44lf.png"
                  alt="Spain icons"
                  width={40}
                />
                <p style={{ paddingTop: "13%" }}>Español</p>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
          >
            <div className={styles.policy}>
              <Link href="/privacy">
                <p>Términos de Servicio |</p>
              </Link>
              <Link href="/privacy">
                <p>Política de Privacidad |</p>
              </Link>
              <Link href="/cookies">
                <p>Política de Cookies</p>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default FivaFooter
