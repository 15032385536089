import React from "react"
import { Avatar } from "antd"
import { SocialMediaLinks } from "../../constants/SocialMediaLinks"

const DarkSocialMediaIcons: React.FC = () => {
  const { LINK_DIN, YOU_TUBE, TWITTER, INSTAGRAM, FACEBOOK } = SocialMediaLinks
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "0.3rem",
        paddingTop: "1rem",
      }}
    >
      <p style={{ paddingTop: "4%", marginRight: "1rem" }}>Síguenos</p>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.3rem",
          paddingTop: "1rem",
        }}
      >
        <a href={FACEBOOK} target="_blank" rel="noopener noreferrer">
          <Avatar
            size="large"
            style={{
              backgroundColor: "var(--fiva-color)",
              color: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            icon={<img src="/icons/media/facebook.svg" alt="Facebook logo" />}
          />
        </a>

        <a href={LINK_DIN} target="_blank" rel="noopener noreferrer">
          <Avatar
            size="large"
            style={{
              backgroundColor: "var(--fiva-color)",
              color: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            icon={<img src="/icons/media/linkedin.svg" alt="LinkedIn logo" />}
          />
        </a>

        <a href={TWITTER} target="_blank" rel="noopener noreferrer">
          <Avatar
            size="large"
            style={{
              backgroundColor: "var(--fiva-color)",
              color: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              padding: "8px",
            }}
            icon={<img src="/icons/media/x-twitter.svg" alt="Twitter logo" />}
          />
        </a>

        <a href={INSTAGRAM} target="_blank" rel="noopener noreferrer">
          <Avatar
            size="large"
            style={{
              backgroundColor: "var(--fiva-color)",
              color: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            icon={<img src="/icons/media/pinterest.svg" alt="Instagram logo" />}
          />
        </a>

        <a href={YOU_TUBE} target="_blank" rel="noopener noreferrer">
          <Avatar
            size="large"
            style={{
              backgroundColor: "var(--fiva-color)",
              color: "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            icon={<img src="/icons/media/youtube.svg" alt="YouTube logo" />}
          />
        </a>
      </div>
    </div>
  )
}
export default DarkSocialMediaIcons
